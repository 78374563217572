//<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card">

        <Toolbar class="p-mb-4">
          <template #start>
            <div style="font-size: 1.4em; color: inherit">{{ $t('Orders archive') }}</div>
          </template>
          <template #end>
<!--            <div class="p-input-icon-left">-->
<!--              <i class="pi pi-search" />-->
<!--              <InputText @input="searchItems($event.target.value)" v-model="searchData" :placeholder="$t('Search') + '...'" />-->
<!--            </div>-->
            <SearchInput :disabled="dataTableIsLoading" @change-processed-search-value="changeProcessedSearchValue" v-model="searchData" @show-spinner="isLoading = true"/>
          </template>
        </Toolbar>

        <Spinner v-if="isLoading"></Spinner>
<!--        <DesktopDataTable v-if="!isLoading && !$store.state.tabletLayout"-->
        <DesktopDataTable v-if="!isLoading"
                          :dataTableIsLoading="dataTableIsLoading"
                          :showHeader="false"
                          :itemsArrayIsEmpty="itemsArrayIsEmpty"
                          :sort="sort"
                          :showSearchInput="false"
                          :actionButtonsCount="actionButtonsCount"
                          @sort-items="sortItems"
                          :headers="[
                    // {name: 'number', title: 'Number', width: '100px', sortable: true},
                    // {name: 'customer', title: 'Customer', width: '16%', sortable: false},
                    // {name: 'order', title: 'Order data', width: '18%', sortable: false},
                    // // {name: 'car_logo', title: '', width: '54px', sortable: false},
                    // {name: 'car', title: 'Car data', width: '14%', sortable: false},
                    // {name: 'grand_total', title: 'Grand total', width: '10%', sortable: true},
                    // {name: 'state', title: 'State', width: '14%', sortable: true},
                    // {name: 'start', title: 'Start', width: '10%', sortable: true},
                    // {name: 'mileage', title: 'Mileage', width: '9%', sortable: false},
                    // {name: 'archived_at', title: 'Archived', width: '14%', sortable: true},
                    {name: 'start', title: 'Start', sortable: true},
                    {name: 'number', title: 'Number', sortable: true},
                    {name: 'customer', title: 'Customer', sortable: false},
                    {name: 'order', title: 'Order data', sortable: false},
                     {name: 'time', title: 'Time', sortable: true},
                    // {name: 'car_logo', title: '', width: '54px', sortable: false},
                    {name: 'car', title: 'Car data', sortable: false},
                    // {name: 'grand_total', title: 'Amount', width: '120px', minWidth: '120px', sortable: true},
                     {name: 'masters', title: 'Masters', sortable: false},
                     // {name: 'invoice', title: 'Invoice', sortable: false},
                    {name: 'invoice', title: 'Invoice', sortable: false},
                    {name: 'grand_total', title: 'Sum', sortable: true},
                    {name: 'state', title: 'State', sortable: true},
                    // {name: 'mileage', title: 'Mileage', width: '110px', minWidth: '110px', sortable: false},
                    // {name: 'mileage', title: 'Mileage', sortable: false},
                    {name: 'archived_at', title: 'Archived', sortable: true},
                ]">
          <template v-slot:body>
            <template  v-for="(item, index) of items" :key="index">
              <tr :class="{'updated-item': isItemUpdated(item.id)}">
                <td>
                  <span v-if="item.start">{{ formatDate(item.start) }}</span>
<!--                  <div style="font-size: 0.85em" v-if="item.mileage">-->
<!--                    {{ $t('Mileage') }}:-->
<!--                    <span>{{ formatMileage(item.mileage) }} {{ $t(settings.measurements.mileage) }}</span>-->
<!--                  </div>-->
                </td>
                <td>
                  <div>{{ item.number }}</div>
                  <div v-if="item.parentOffer?.number" class="p-pt-1" style="font-size: 0.85em">
                    <router-link :to="{ path: '/offers', query: { search: item.parentOffer.number } }" target="_blank">
                      <i style="font-size: 0.90em" class="ti-clipboard p-mr-1"></i>
                      <span>{{ item.parentOffer.number }}</span>
                    </router-link>
                  </div>
                  <div v-else-if="item.parentOrder?.number" class="p-pt-1" style="font-size: 0.85em">
                    <router-link :to="{ path: `/orders/${item.parentOrder.number}` }" target="_blank">
                      <i style="font-size: 0.90em" class="ti-hummer p-mr-1"></i>
                      <span>{{ item.parentOrder.number }}</span>
                    </router-link>
                  </div>
                </td>
                <td>
                  <TableCustomerData :customer="item.customer" :showPhoneNumber="false" @change-customer-status="changeCustomerStatus"/>
                </td>
<!--                <td>-->
<!--                    <span>-->
<!--&lt;!&ndash;                      <a href="#" @click.prevent="toggleShowPositionTable(item)" class="table-title-link">&ndash;&gt;-->
<!--&lt;!&ndash;                        <i style="font-size: 0.9em" class="pi pi-minus" v-if="item.showOrderData"></i>&ndash;&gt;-->
<!--&lt;!&ndash;                        <i style="font-size: 0.9em;" class="pi pi-plus" v-else></i>&ndash;&gt;-->
<!--&lt;!&ndash;                        <span class="text">{{ item.name  }}</span>&ndash;&gt;-->
<!--&lt;!&ndash;                      </a>&ndash;&gt;-->
<!--                      <a href="#" @click.prevent="toggleShowPositionTable(item)" class="table-title-link">-->
<!--                        <i style="font-size: 0.9em" class="pi" :class="{'pi-minus': item.showPositionsTable, 'pi-plus':!item.showPositionsTable}"></i>-->
<!--                        <span class="text">{{ item.name }}</span>-->
<!--                      </a>-->
<!--                      <span @click="toggleWarning(item.id)" v-show="item.staff_comment" class="p-link icon">-->
<!--                        <i class="ti-comment-alt" :class="{'warning-color':item.warning}"></i>-->
<!--                      </span>-->
<!--                    </span>-->
<!--                  <div class="table-small-text p-mt-1">-->
<!--                    {{ $t('Created') }}:-->
<!--                    <span v-if="item.created_at">{{ formatDate(item.created_at) || '' }}, </span>-->
<!--                    <span v-if="item.creator" class="table-date-line">-->
<!--                        <span v-if="item.creator.type === constants.userTypes.legal_entity && item.creator.company_name">{{ item.creator.company_name }}<span> ({{ item.creator.first_name }} {{ item.creator.last_name }})</span></span>-->
<!--                        <span v-else>{{ item.creator.first_name }} {{ item.creator.last_name }}</span>-->
<!--                      </span>-->
<!--                  </div>-->
<!--                </td>-->
                <td>
                  <div>
                    <a href="#" @click.prevent="toggleShowPositionTable(item)">
                      <i style="font-size: 0.9em" class="p-mr-1 pi" :class="item.showPositionsTable ? 'pi-minus': 'pi-plus'"></i>
                      <span class="text">{{ item.name || item.number }}</span>
                    </a>
<!--                    <div @click="toggleWarning(item)" v-show="item.staff_comment" class="p-link icon p-mr-2">-->
<!--                      <i class="ti-comment-alt" :class="{'warning-color':item.is_warning}"></i>-->
<!--                    </div>-->
<!--                    <div v-if="item.parentOffer?.number" style="font-size: 0.85em;">-->
<!--                      <span class="p-mr-1">From:</span>-->
<!--                      <router-link :to="{ path: '/offers', query: { search: item.parentOffer.number } }" target="_blank">-->
<!--                        <i style="font-size: 0.90em" class="ti-clipboard p-mr-1"></i>-->
<!--                        <span>{{ item.parentOffer.number }}</span>-->
<!--                      </router-link>-->
<!--                    </div>-->

<!--                    <div v-else-if="item.parentOrder?.number" style="font-size: 0.85em;">-->
<!--                      <span class="p-mr-1">From:</span>-->
<!--                      <router-link :to="{ path: `/orders/${item.parentOrder.number}` }" target="_blank">-->
<!--                        <i style="font-size: 0.90em" class="ti-hummer"></i>-->
<!--                        <span>{{ item.parentOrder.number }}</span>-->
<!--                      </router-link>-->
<!--                    </div>-->
                  </div>
                  <div class="table-small-text">
                      <span :class="{'order-created-by-customer':item.is_created_by_customer}">
                        <span>{{ $t('Created') }}: </span>
                        <span v-if="item.created_at">{{ formatDate(item.created_at) }}, </span>
                        <span v-if="item.creator">
<!--                          <span v-if="item.creator.type === constants.userTypes.legal_entity && item.creator.company_name">{{ item.creator.company_name }}<span> ({{ item.creator.first_name }} {{ item.creator.last_name }})</span></span>-->
                          <span v-if="item.creator.type === constants.userTypes.legal_entity && item.creator.company_name">{{ item.creator.company_name }}</span>
                          <span v-else>{{ item.creator.first_name }} {{ item.creator.last_name }}</span>
                        </span>
                      </span>
                  </div>
                </td>
<!--                <td>-->
<!--                  <div v-if="item.car?.make?.logo_path" class="p-mr-2">-->
<!--                    <img style="max-width: 40px; max-height: 40px; display: block" :src="settings.apiEndPoint + settings.pathToCarLogosDir + item.car.make.logo_path">-->
<!--                  </div>-->
<!--                </td>-->
                <td>
                  <span v-if="item.time && +item.time !== 0">{{ item.time }} {{ $t('h') }}.</span>
                  <i v-else class="ti-minus"></i>
                </td>
                <td>
                    <span v-if="item.car" >
                      <router-link :to="{ path: `/cars/${item.car.id}` }" target="_blank">
                        <i class="ti-car p-mr-1"></i>
                        <span>{{ item.car.make?.name ?? '' }} {{ item.car.model?.name ?? '' }}</span>
<!--                        <span class="p-mr-1" v-if="item.car.make">{{ item.car.make.name }}</span>-->
<!--                        <span class="p-mr-1" v-if="item.car.model">{{ item.car.model.name }}</span>-->
                      </router-link>
                      <div class="table-small-text p-d-flex p-ai-center">
                        <div>
                          <span>{{ item.car.plate_number }}</span>
                          <span v-if="item.car.year">, {{ item.car.year }}</span>
                        </div>
                        <CarDataLink :plateNumber="item.car.plate_number" :showOnlyIcon="true"/>
                      </div>
                    </span>
                  <i v-else class="ti-minus"></i>
                </td>
                <td class="avatar-td">
                  <AvatarsCell :masters="item.masters"/>
                </td>
                <td>
                  <span v-if="item.invoiceNumber">{{ item.invoiceNumber }}</span>
                  <i v-else class="ti-minus"></i>
                </td>
                <td>
<!--                  <span v-if="item.positions && item.positions.filter(position => position.status === 1).length">{{ formatCurrency(+item.positions.filter(position => position.status === 1).reduce((sum, position) => sum + ((position.sell_price - ((position.discount / 100) * position.sell_price)) * position.qty) * (((position.tax ? position.tax.value : 0) / 100) + 1), 0) + (item.consumable ? ((item.consumable.sell_price - (((item.consumable.discount || 0) / 100) * item.consumable.sell_price)) * item.consumable.qty) * ((item.consumable.tax.value / 100) + 1) : 0)) }}</span>-->
<!--                  <span v-else>{{ formatCurrency(0) }}</span>-->
                  <span>{{ formatCurrency(item.grand_total) }}</span>
                  <div v-if="item.is_invoiced && item.unpaidSum && +item.unpaidSum" style="font-size: 0.8rem" class="warning-color">
                    Unpaid: {{ formatCurrency(item.unpaidSum) }}
                  </div>
                  <div v-else-if="!item.is_invoiced && (item.state === 7 || item.state === 8) && +item.grand_total" style="font-size: 0.8rem" class="warning-color">
                    Unpaid: {{ formatCurrency(item.grand_total) }}
                  </div>
                </td>
                <td>
                  <OrderStateButton :item="item"/>
                </td>
<!--                <td>-->
<!--                  <span v-if="item.mileage">{{ formatMileage(item.mileage) }} {{ $t(settings.measurements.mileage) }}</span>-->
<!--                  <i v-else class="ti-minus"></i>-->
<!--                </td>-->
                <td>
                  <span v-if="item.is_archived" class="table-small-text p-mt-1">
                    <span v-if="item.archived_at">{{ formatDate(item.archived_at) || '' }}, </span>
                    <span v-if="item.archivedBy" class="table-date-line">
                      <span v-if="item.archivedBy.type === constants.userTypes.legal_entity && item.archivedBy.company_name">{{ item.archivedBy.company_name }}<span> ({{ item.archivedBy.first_name }} {{ item.archivedBy.last_name }})</span></span>
                      <span v-else>{{ item.archivedBy.first_name }} {{ item.archivedBy.last_name }}</span>
                    </span>
                  </span>
                  <span v-else>-</span>
                </td>
<!--                <td><a @click.prevent href="#" class="p-link table-link-icon"><i class="ti-printer"></i></a></td>-->
                <td>
                  <div class="p-d-flex">
                    <router-link :to="{ path: `/orders/${item.number}` }"><ViewButton/></router-link>
                  </div>
                </td>
              </tr>
              <tr v-show="item.showPositionsTable" class="table-opened-info">
                <td colspan="20" style="padding:0!important;">
                  <PositionsTable :item="item" :isSpoilerTable="true" @update-items="updateItems"/>
                </td>
              </tr>
            </template>
          </template>
        </DesktopDataTable>

        <OwnPaginator v-show="!itemsArrayIsEmpty"
                      :rows="pagination.perPage"
                      :currentPage="pagination.currentPage - 1"
                      :totalPages="pagination.pageCount"
                      :totalRecords="pagination.totalCount"
                      :rowsPerPageOptions="[5,10,25,50,100,200]"
                      :pageLinkSize="11">
        </OwnPaginator>

        <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>
      </div>

    </div>
  </div>
</template>

<script>
import httpClient from '@/services/http.services'
import OrderStateButton from '@/pages/orders/components/OrderStateButton'
import formatMixins from '@/mixins/formatMixins'
import PositionsTable from '@/components/PositionsTable'
import datatableMixins from '@/mixins/datatableMixins'
import httpMixins from "@/mixins/httpMixins";
import TableCustomerData from "@/components/TableCustomerData";
import settings from "@/settings";
import AvatarsCell from "@/components/AvatarsCell";
import CarDataLink from "@/pages/cars/components/CarDataLink";

export default {
  mixins: [ formatMixins, datatableMixins, httpMixins ],
  components: { OrderStateButton, PositionsTable, TableCustomerData, AvatarsCell,CarDataLink },
  props: {
    user: Object,
  },
	data() {
		return {
      openedItemsIds: [],
      // fieldsExpand: 'responsible,customer,creator,archivedBy,positions,positions.history,positions.request_state_history,consumable,car,area,offer,recommendation_history,state_history,comments_history',
      fieldsExpand: 'customer,customer.customerStatus,creator,archivedBy,positions,positions.history,positions.request_state_history,consumable,car,recommendation_history,state_history,comments_history,parentOffer,parentOrder,unpaidSum,masters,invoiceNumber',
      apiLink: 'order/get-orders',
      routePath: '/orders-archive',
      sort: {
        column: 'archived_at',
        direction: '-',
      },
		}
	},
  mounted() {
    if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    this.checkRouteQuery()
    this.getItems()
  },
  methods: {
    async toggleWarning(item) {
      // const item = this.items.filter(item => item.id === itemId)[0]
      // const value = !item.warning
      // try {
      //   const { status } = await httpClient.post('order/toggle-warning', { id: itemId, state: value })
      //   if (status === 200) {
      //     item.warning = !item.warning
      //   }
      // } catch(err) {
      //   this.showError(err)
      // }
      if (!item) return false
      item.is_warning = !item.is_warning
      try {
        const { status } = await httpClient.post('order/toggle-warning', { id: item.id, state: item.is_warning })
        if (status !== 200) {
          item.is_warning = !item.is_warning
        }
      } catch(err) {
        item.is_warning = !item.is_warning
        this.showError(err)
      }
    },
    // toggleItemData(item, positionsEmpty = false) {
    //   if (positionsEmpty) return false
    //   item.showOrderData = !item.showOrderData
    //   this.openedItemsIds = this.items.filter(item => item.showOrderData)?.map(i => { return i.id })
    // },
    async getItems(showSpinner = false) {
      this.dataTableIsLoading = true
      if (showSpinner) {
        this.isLoading = true
      }
      try {
        // const { status, data, headers } = await httpClient.post(`${this.apiLink}?archived=true&page=${this.pagination.currentPage}&per-page=${this.pagination.perPage}&sort=${this.sort.direction + this.sort.column}&search=${this.searchData}&expand=${this.fieldsExpand}`, { customerId: this.$route.params.id })
        const { status, data, headers } = await httpClient({
          // const { data } = await httpClient({
          url: this.apiLink,
          // method: 'post',
          params: {
            archived: true,
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.processedSearchValue ?? null,
            expand: this.fieldsExpand,
          },
          // data: {}
        })
        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']
          this.items = data

          if (this.openedItemsIds.length) {
            this.items.forEach(item => {
              if (this.openedItemsIds.indexOf(item.id) !== -1) {
                item.showPositionsTable = true
              }
            })
          }

          if (!this.items?.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.dataTableIsLoading = false
        this.disableEditButton = false
      }
    },
	},
  computed: {
    itemToDeleteData() {
      return 'delete data'
    },
    // itemsArrayIsEmpty() {
    //   return !(this.items && this.items.length)
    // },
    actionButtonsCount() {
      return 1
    },
  }
}
</script>