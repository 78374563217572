<template>
  <div>
    <span v-show="!showOnlyIcon" class="p-mr-2">{{ $t('Car data') }}:</span>
    <a target="_blank"
       v-tooltip.top="showOnlyIcon ? $t('Car data') : null"
       :href="'https://eteenindus.mnt.ee/public/soidukTaustakontroll.jsf?regMark=' + plateNumber"
       draggable="false"
       :class="{'p-p-2':showOnlyIcon}">
      <i style="font-size: 0.85rem" class="pi pi-external-link"></i>
      <span v-show="!showOnlyIcon" class="p-ml-1">{{ plateNumber }}</span>
    </a>
  </div>
</template>

<script>
export default {
  name: "CarDataLink",
  props: {
    plateNumber: {
      type: [String, null],
      default: null
    },
    showOnlyIcon: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped>

</style>